.home {
  height: 100vh;
  display: flex;
  align-items: center;
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 85px;
  }
  &__block {
    min-width: 340px;
    height: 340px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__picture {
    width: 50px;
    height: 100px;
    border-radius: 50%;
    position: relative;
  }
  &__animBlock {
    min-width: 340px;
    height: 340px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 50%;
    // border: 1px solid #000;
  }
  .animBlock {
    &__one,
    &__two,
    &__three {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      border-bottom: 15px solid #008bd9;
      border-top: 1px solid #000;

      border-radius: 50%;
    }
    &__one {
      transform: translate(-50%, -50%) rotate3d(1, 0, 1, 120deg);
      animation: one 3s linear infinite;
    }
    &__two {
      transform: translate(-50%, -50%) rotate3d(0, 1, 1, 120deg);
      animation: two 3s linear infinite;
    }
    &__three {
      transform: translate(-50%, -50%) rotate3d(1, 0, 0, 100deg);
      animation: three 3s linear infinite;
    }
  }

  &__content {
    text-align: center;
  }
  &__title {
    font-size: 48px;
    position: relative;
    margin-bottom: 20px;
    &::after {
      content: "";
      height: 1px;
      width: 230px;
      background-color: #008bd9;
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &__subtitle {
    font-size: 30px;
    margin-bottom: 20px;
  }
  &__btn {
    padding: 17px 30px;
    width: 286px;
    font-size: 22px;
    background-color: #008bd9;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    border-radius: 6px;

    &:hover {
      background-color: #f3f3f3;
      color: #000;
      transition: 0.3s;
    }
  }
}

@media (max-width: 780px) {
  .home {
    margin-top: -50px;
    .container {
      flex-direction: column;
      gap: 30px;
    }
    &__picture {
      min-width: 300px;
      height: 300px;
    }
    &__title {
      font-size: 40px;
    }
    &__subtitle {
      font-size: 26px;
    }
  }
}
@media (max-width: 600px) {
  .home {
    &__picture {
      min-width: 200px;
      height: 200px;
    }
    &__title {
      font-size: 32px;
      margin-bottom: 15px;
      &::after {
        bottom: -8px;
      }
    }
    &__subtitle {
      font-size: 20px;
    }
    &__btn {
      font-size: 18px;
    }
  }
}

@keyframes one {
  from {
    transform: translate(-50%, -50%) rotate3d(1, 0, 1, 1200deg) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate3d(1, 0, 1, 1200deg) rotate(360deg);
  }
}
@keyframes two {
  from {
    transform: translate(-50%, -50%) rotate3d(0, 1, 1, 100deg) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate3d(0, 1, 1, 100deg) rotate(360deg);
  }
}
@keyframes three {
  from {
    transform: translate(-50%, -50%) rotate3d(1, 0, 0, 100deg) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate3d(1, 0, 0, 100deg) rotate(360deg);
  }
}
